@font-face {
  font-family: 'NueuHaas';
  src: url('./assets/NeueHaasDisplay-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

html, body {
  height: 100%; 
  margin: 0;
  overflow: hidden; 
}


p {
  color: #fff7ea;
  opacity: 95%;
  font-size: 2.5rem;
  font-family: 'NueuHaas';
  filter: blur(0.5px); 
}


body {
  background-color: #0b0c0c;
  margin: 0;
  height: 100vh;
}

.ig-div {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 1px;
  right: 1px;
  z-index: 1000;
  margin: 10px;
  opacity: 50%;
}

.link-div-text {
  font-size: 1.2rem;
  margin: 3px;
  transition: color 0.04s ease-in-out;
  filter: blur(0.7px); 
}

.ig-div a:hover .link-div-text {
  color: #20718B; 
  opacity: 1;
}

a {
  text-decoration: none;
}

.empty-div1 {
  flex: 2;
}

.form-div {
  flex: 2;
  align-items: flex-start;
}

.subscribed-text {
  color: #d56d69;
  opacity: 95%;
  font-size: 2.5rem;
  font-family: 'NueuHaas';
  filter: blur(0.5px); 
}

.fadeText1-container {
  position: relative;
  cursor: pointer;
  height: 100px; 
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fadeText1-container.active {
  pointer-events: auto; 
}

.fadeText1, .fadeText2 {
  opacity: 0;
  transition: opacity 0.04s ease-in-out;
  position: absolute;
}

.fadeText1.active {
  opacity: 1;
}

.fadeText2.active {
  opacity: 1;
}

.fadeText2 {
  pointer-events: none; 
}

.centerBox {
  position: relative;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
