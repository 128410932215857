
.subscribeForm {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 
  margin: 0 auto; 
  width: 60vw;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  box-sizing: border-box; 
  opacity: 1;
}

.name-div {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.name-div.fade-out {
  opacity: 0;
}

.name-div.fade-in {
  opacity: 1;
}

.email-div {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.email-div.fade-in {
  opacity: 1;
}

.email-div.fade-out {
  opacity: 0;
}

.subscribeForm div {
  width: 100%; 
  box-sizing: border-box; 
}

.subscribeForm p {
  margin: 0
}

.subscribeForm label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  text-align: center; 
  color: white; 
}

.subscribeForm input {
  width: 80%; 
  font-size: 1.4rem;
  background: transparent; 
  border: none; 
  outline: none; 
  box-sizing: border-box; 
  text-align: center;
  padding: 10px;
  color: #fbfcf4;
  opacity: 50%;
  font-family: 'NueuHaas';
  filter: blur(0.8px); 
}

.subscribeForm input.submitted {
  color: #d56d69;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

p {
  margin: 0;
}



